import './navigation.js';
import './skip-link-focus-fix.js'
(( $ ) => {

	function headerPadding() {
		// Header padding
		var header = $('.site-header-hero');
		var headerHeight = header.outerHeight(true);
		var hero = $('.hero-padding');
		hero.css('padding-top', headerHeight);
	}

	function favoriteButton() {
		$('.favorite-btn').click(function(){

			var isFavorite = $(this).attr('data-is-favorite');
			var postId = $(this).attr('data-post-id');
			var buttonClass = '.favorite-btn-' + postId;

			if(isFavorite == 'false') {
				$.ajax({
					type: "POST",
					url: froggy_ajax.ajax_url,
					data: {
						action: 'save_favorites',
						post_id: postId,
					},
					success: function(data){
						$(buttonClass).attr('data-is-favorite', 'true');
						$(buttonClass).html('Poista suosikeista<i class="fas fa-heart text-primary ml-2 text-2xl">');
					}
				});
			} else {
				$.ajax({
					type: "POST",
					url: froggy_ajax.ajax_url,
					data: {
						action: 'delete_favorites',
						post_id: postId,
					},
					success: function(data){
						$(buttonClass).attr('data-is-favorite', 'false');
						$(buttonClass).html('Lisää suosikiksi<i class="far fa-heart ml-2 text-2xl">');
					}
				});
			}

		});
	}


	$(document).ready( () => {

		$('.content-area').each(function(){
      var highestBox = 0;
      $(this).find('.tietokortti-content').each(function(){
        if($(this).outerHeight() > highestBox){
            highestBox = $(this).outerHeight();
        }
      })
      $(this).find('.tietokortti-content').outerHeight(highestBox);
    });

		headerPadding();

	});

	$(window).resize(() => {

		headerPadding();

	});

	favoriteButton();

	$('.orientation-btn').click(function(){
		var postId = $(this).attr('data-post-id');
		var nextLink = $(this).attr('data-next');
		var buttonClass = '.orientation-btn';
		$.ajax({
			type: "POST",
			url: froggy_ajax.ajax_url,
			data: {
				action: 'save_orientation',
				post_id: postId,
			},
			success: function(data){
				$(buttonClass).attr('disabled', true);
				$(buttonClass).removeClass('orientation-btn');
				$(buttonClass).html('Siirry seuraavaan osioon');
				window.location.href = nextLink;
			}
		});
	});

	document.addEventListener('facetwp-loaded', function() {
		if (FWP.loaded) {
			favoriteButton();
		}
 	});


} )( jQuery );
